<template>
    <div>
        <div class="mobile-wine-list-wrap" v-if="wineList.length > 0">
            <div class="wine-list-content">
                <WineCardItem
                v-for="wineInfo in wineList"
                :wine-info="wineInfo"
                v-bind:key="wineInfo.key"
                :is-redeem="isRedeem">
                </WineCardItem>
                <infinite-loading @infinite="nextPage">
                    <div slot="no-results">
                        <!-- 검색 결과 없을 때 -->
                        <!--<span>결과 없어용..!</span>-->
                    </div>
                    <div slot="no-more">
                        <!-- 검색 결과 더이상 없을 때 -->
                        <!--<span>더이상 결과 없어용..!</span>-->
                    </div>
                </infinite-loading>
            </div>
        </div>
        
      <div class="pt-[160px] flex flex-col items-center w-full justify-center gap-[20px]"
           v-if="wineList.length === 0">
        <img src="../../assets/images/empty.png" class="w-[90px] h-[90px]" />
        <p class="text-[24px] text-lightDark font-[Suit-Medium]">NFT가 없습니다</p>
      </div>

    </div>
</template>

<script>
    import WineCardItem from "../common/WineCardItem";
    export default {
        name: "MobileWineList",
        components: {WineCardItem},
        created() {

        },
        mounted() {

        },
        props: {
            wineList: {},
            nextPage:{
                default: () => {},
                type:Function,
                required:true
            },
            isRedeem: {
                default: false
            },
            noResultText: {
                default: "NFT 리스트를 로드 중입니다. 잠시만 기다려 주십시오."
            }
        }
    }
</script>

<style scoped>

</style>
